const Segment = {
  identify: async function() {
    if (typeof analytics === "undefined") {
      console.error("'analytics' undefined");
      return;
    }

    const user = await ProspectAPI.getUser();

    if (user && user.id) {
      return analytics.identify(user.id);
    }
  },

  page: function() {
    if (typeof analytics === "undefined") {
      console.error("'analytics' undefined");
      return;
    }

    analytics.page.apply(this, arguments);
  },

  track: function() {
    if (typeof analytics === "undefined") {
      console.error("'analytics' undefined");
      return;
    }

    analytics.track.apply(this, arguments);
  },
}
